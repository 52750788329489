import { createContext, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Header from "./components/Header";
import styles from "./style.module.scss";
import "./style.scss";

const DefaultLayoutContext = createContext({});

const DefaultLayout = (props: any) => {
  const { t } = useTranslation();
  const { navType } = props;
  const { pathname, search } = useLocation();

  useEffect(() => {
    const contentContainer = document.getElementById("contentContainer");
    contentContainer?.scrollTo(0, 0);
  }, [pathname, search]);

  return (
    <div className={styles.mainLayout}>
      <DefaultLayoutContext.Provider value={{}}>
        <div
          className={styles.main}
          style={{
            overflow: "scroll",
            height: navType.doNotInitWindowHeight
              ? ""
              : "calc(var(--vh, 1vh) * 100)", //vh * 100,
            margin: "0 auto",
          }}
        >
          {navType.hideHeader ? (
            <></>
          ) : (
            <>
              <Header />
            </>
          )}
          <div
            className={styles.container}
            style={
              navType.backgroundColor
                ? {
                    background: navType.backgroundColor,
                  }
                : {}
            }
            id="contentContainer"
          >
            {props.children}
          </div>
          {/* {navType.hideFooter ? (
            <></>
          ) : (
            <>
              <Footer />
            </>
          )} */}
        </div>
      </DefaultLayoutContext.Provider>
    </div>
  );
};
export default DefaultLayout;

export const useDefaultLayout = () => {
  const LayoutContext = useContext(DefaultLayoutContext);
  return LayoutContext;
};
