import Home from "../pages/Home";

// navType { hideHeader, hideFooter }
const RouterList = [
  {
    path: "/",
    name: "home",
    component: Home,
    navType: {
      backgroundColor: "#FFF",
    },
  },
];

export default RouterList;
