import { Suspense, useContext, useEffect, useRef, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { LoadingView } from "../components";
import RouterContext from "./RouterContext";
import layouts from "./layouts";
import RouterList from "./router";

const findRouteConfig = (currentPath = "") => {
  let target: any = RouterList.find((route) => {
    return route.path === currentPath ?? window.location.pathname;
  });
  if (!target) return "default";

  return !!target ? target : {}; //background
};

function withRouter(Component: any) {
  function ComponentWithRouterProp(props: any) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const RouterRender = ({
  router,
  match,
  showTransform,
}: {
  router: any;
  match: any;
  showTransform: any;
}) => {
  let { location } = router;
  // const { changeLayout, changeNavType } = useContext(RouterContext);
  const { changeNavType } = useContext(RouterContext);

  useEffect(() => {
    changeNavType(findRouteConfig(location.pathname)?.navType);
  }, [location]);

  //dont know why cannot read baseVal, so i added a ref
  const nodeRef = useRef(null);

  return (
    <TransitionGroup>
      <CSSTransition
        nodeRef={nodeRef}
        classNames={"fade"}
        in={match != null}
        timeout={0}
        key={location?.key}
        appear
      >
        <Suspense fallback={<LoadingView />}>
          <Routes>
            {RouterList.map((route: any, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={<route.component {...route.props} />}
                />
              );
            })}
          </Routes>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  );
};

const RouterRenderWithRouter = withRouter(RouterRender);

const ProcessRouter = () => {
  // const [layout, setLayout] = useState(findRouteConfig()?.layout ?? "default");
  const [navType, setNavType] = useState({});

  const actions = {
    changeNavType: (navType = {}) => {
      setNavType(navType ?? {});
    },
  };

  var LayoutComponent = layouts["default"];

  return (
    <RouterContext.Provider value={actions}>
      <Router>
        <LayoutComponent navType={navType ? navType : { type: "default" }}>
          <RouterRenderWithRouter />
        </LayoutComponent>
      </Router>
    </RouterContext.Provider>
  );
};

export default ProcessRouter;
