import { LoadingContextProvider } from "./LoadingContext";

const ContextList = [
  { name: "LoadingContext", context: LoadingContextProvider },
];

function Context(props: any) {
  let output = props.children;
  ContextList.forEach((contextInfo) => {
    output = <contextInfo.context>{output}</contextInfo.context>;
  });
  return output;
}

export { LoadingContextProvider };

export default Context;
