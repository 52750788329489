import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import localStorageKey from "../enumerations/localStorageKey";
import enUS from "../locales/en-us.json";
import zhTW from "../locales/zh-tw.json";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  "zh-TW": { translation: zhTW },
  "en-US": { translation: enUS },
};

const nowLangProcess = () => {
  const localLang = window.localStorage.getItem(localStorageKey.DEFAULT_LANG);
  if (localLang) {
    return localLang;
  }
  if (process.env.REACT_APP_DEFAULT_LANG) {
    if (typeof window !== "undefined") {
      window.localStorage.setItem(
        localStorageKey.DEFAULT_LANG,
        process.env.REACT_APP_DEFAULT_LANG,
      );
    }
  }

  return process.env.REACT_APP_DEFAULT_LANG;
};

const nowLang = nowLangProcess();
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: nowLang,
    fallbackLng: "zh-TW",
    keySeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
