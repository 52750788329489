import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConfigProvider } from "antd";
import enUS from "antd/locale/en_US";
import zhTW from "antd/locale/zh_TW";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import localStorageKey from "./enumerations/localStorageKey";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// import "antd/dist/antd.variable.min.css";

// const currentTheme = require(
//   `./theme/${process.env.REACT_APP_PROD_NAME_DEV}/theme.json`
// );
const defaultTheme = require(`./theme/defaultTheme.json`);
// const finalTheme = { ...defaultTheme, ...currentTheme };
const finalTheme = { ...defaultTheme };

ConfigProvider.config({
  theme: {
    token: finalTheme,
  },
});

const localeDict: any = {
  "zh-TW": zhTW,
  "en-US": enUS,
  default: zhTW,
};

const lang =
  window.localStorage.getItem(localStorageKey.DEFAULT_LANG) ??
  process.env.REACT_APP_DEFAULT_LANG ??
  "zh-TW";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 3000,
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider
          // autoInsertSpaceInButton={false}
          theme={{ token: finalTheme }}
          locale={localeDict[lang] ?? localeDict.default}
        >
          <App />
        </ConfigProvider>
      </QueryClientProvider>
    </React.StrictMode>
  </React.StrictMode>
);

reportWebVitals();
