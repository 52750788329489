import { Anchor, Image } from "antd";
import { Popup } from "antd-mobile";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logo from "../../images/logo_black.png";
import styles from "./style.module.scss";
import "./style.scss";

const Header = () => {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const topRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [showHeader, setShowHeader] = useState<boolean>(true);
  const [targetOffset, setTargetOffset] = useState<number>();
  let scrollTop = 0;

  const scrollProcess = () => {
    const currentScrollTop = document.getElementById("home")?.scrollTop || 0;
    if (currentScrollTop - scrollTop > 0 && currentScrollTop >= 10) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
    scrollTop = currentScrollTop;
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollProcess, true);
    return () => {
      window.removeEventListener("scroll", scrollProcess, true);
    };
  }, []);

  useEffect(() => {
    setTargetOffset(topRef.current?.clientHeight);
  }, []);

  return (
    <>
      <div
        className={`${styles.header} ${!showHeader && styles.disable}`}
        ref={topRef}
      >
        <div className={styles.container}>
          <Image
            src={logo}
            preview={false}
            className={styles.logo}
            onClick={() => navigator("/")}
          />
          <div className={`${styles.menu} menu`}>
            <Anchor
              targetOffset={targetOffset}
              direction="horizontal"
              getContainer={() =>
                document.getElementById("home") as HTMLElement
              }
              showInkInFixed={true}
              items={[
                {
                  key: "part-1",
                  href: "#part-1",
                  title: `${t("menu.home")}`,
                },
                {
                  key: "part-2",
                  href: "#part-2",
                  title: `${t("menu.service")}`,
                },
                {
                  key: "part-3",
                  href: "#part-3",
                  title: `${t("menu.partner")}`,
                },
                {
                  key: "part-4",
                  href: "#part-4",
                  title: `${t("menu.version")}`,
                },
                {
                  key: "part-5",
                  href: "#part-5",
                  title: `${t("menu.contact")}`,
                },
              ]}
            ></Anchor>
            <div
              className={`${styles.hamburgerContainer} ${visible && styles.active}`}
              onClick={() => setVisible(!visible)}
            >
              <div className={styles.hamburger}></div>
              <Popup
                visible={visible}
                onMaskClick={() => {
                  setVisible(false);
                }}
                closeOnSwipe={true}
                onClose={() => {
                  setVisible(false);
                }}
                bodyStyle={{
                  minHeight: "230px",
                  marginTop: "64px",
                  borderRadius: "0 0 32px 32px",
                  textAlign: "center",
                }}
                position="top"
              >
                <Anchor
                  onClick={() => setVisible(false)}
                  targetOffset={targetOffset}
                  getContainer={() =>
                    document.getElementById("home") as HTMLElement
                  }
                  showInkInFixed={true}
                  items={[
                    {
                      key: "part-1",
                      href: "#part-1",
                      title: `${t("menu.home")}`,
                    },
                    {
                      key: "part-2",
                      href: "#part-2",
                      title: `${t("menu.service")}`,
                    },
                    {
                      key: "part-3",
                      href: "#part-3",
                      title: `${t("menu.partner")}`,
                    },
                    {
                      key: "part-4",
                      href: "#part-4",
                      title: `${t("menu.version")}`,
                    },
                    {
                      key: "part-5",
                      href: "#part-5",
                      title: `${t("menu.contact")}`,
                    },
                  ]}
                />
              </Popup>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
