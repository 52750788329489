import "./App.css";
import "./plugins/i18n";

import { useEffect } from "react";
import Context from "./context";
import RouterRender from "./router/index";
import FontSetting from "./styles/FontSetting";

// const currentTheme = require(`./theme/${process.env.REACT_APP_PROD_NAME_DEV}/theme.json`);
const defaultTheme = require(`./theme/defaultTheme.json`);
// const finalTheme = { ...defaultTheme, ...currentTheme };
const finalTheme = { ...defaultTheme };

function App() {
  const setTheme = () => {
    const r: any = document.querySelector(":root");
    const final = Object.entries(finalTheme);
    final.map((color) => {
      r?.style?.setProperty(color[0], color[1]);
    });
  };
  const initStyle = () => {
    let _vh = window.innerHeight * 0.01;
    window.document.documentElement.style.setProperty("--vh", `${_vh}px`);
  };

  useEffect(() => {
    // initStyle();
    setTheme();
  }, []);

  return (
    <div>
      <Context>
        <FontSetting />
        <RouterRender />
      </Context>
    </div>
  );
}

export default App;
